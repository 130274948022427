.service{
    /* background:#f3f3f3; */
    background: #4CA1AF; 
    background: -webkit-linear-gradient(to top, #C4E0E5, #4CA1AF);  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white); 
    width: 100%;
    margin: 100px 0px;
    padding: 35px 50px 50px 30px;
    /* height: fit-content; */
    
}

.service h1{
    text-align: center;
    margin-bottom: 60px;
}

.serviceItem{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
    /* border: 1px solid black; */
    /* grid-column-gap: 10px; */
    /* margin: 0px 5px; */
}

@media screen and (max-width:800px){
    .serviceItem{
        grid-template-columns: repeat(1,1fr);
    }
}