body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
    --primary: #3acbf7;
}

.btn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border:none;
    cursor: pointer;
}

.btn:hover{
    padding: 8px,20px;
    transition: all 0.3s ease-out;
    background: white;
    color: #6568f4;
    transition: 250ms;
}

.btn--primary{
    background-color: #3acbf7;
    background-color: var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: white;
    padding: 8px,20px;
    border-radius: 4px;
    border: 1px solid #3acbf7;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px,20px;
    border-radius: 4px;
    font-size: 18px;
    color: white;
}

.btn--medium{
    padding: 12px,26px;
    border-radius: 4px;
    font-size: 20px;
    color: white;
}
.NavbarItems{
    background: linear-gradient(105deg, #6e99e6, #093c94);  
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: white;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: 500;
}

.nav-links:hover{
   background-color: black;
   color: white;
   border-radius: 4px;
   transition: all 0.2s ease-out;
}

.fa-bars{
    color: white;
}

.menu-icon{
    display: none;
}

@media screen and (max-width:960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: linear-gradient(105deg, #6e99e6, #093c94); 
        left:0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        margin-top: 18px;
    }

    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: darkblue; 
        border-radius: 0;
    }

    .navbar-logo{
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(25%,50%);
                transform: translate(25%,50%);
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%,60%);
                transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times{
        color: white;
        font-size: 2rem;
    }
}
.slider{
    position: -webkit-sticky;
    position: sticky;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.image{
    /* width: 700px;
    height: 600px; */
    width: 700px;
    /* max-height: 100%;
    display: block; */
}

.right-arrow{
    position: absolute;
    top: 50%;
    right: 5%;
    font-size: 3rem;
    color: black;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.left-arrow{
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 3rem;
    color: black;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}


@media screen and (max-width:1050px),
screen and (max-height:700px)
{

    .image{
        width: 450px;
        height: 400px;
        border-radius: 10px;
    }
    
}

@media screen and (max-width:500px){

    .image{
        width: 300px;
        height: 250px;
        border-radius: 10px;
    }    

    .left-arrow{
       
        top:48%;
    }

    .right-arrow{
        top:48%;
    }
}


@media screen and (max-width:460px){
    .left-arrow{
        left: 3%;
        top:48%;
    }

    .right-arrow{
        right: 3%;
        top:48%;
    }
}

@media screen and (max-width:370px){

    .slider{
        height: 80vh;
    }

    .image{
        width: 250px;
        height: 200px;
        border-radius: 10px;
    }

    .left-arrow{
        left: 10%;
        top:48%;
    }

    .right-arrow{
        right: 10%;
        top:48%;
    }
    
}
.aboutUs{
    background:#f3f3f3;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    padding: 40px 100px;
    color: black;
    /* justify-content: center; */
    /* border: 1px solid black; */
    /* background: #4CA1AF; 
    background: -webkit-linear-gradient(to top, #C4E0E5, #4CA1AF);  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white);  */
}

.logo{
    height: 50vh;
    border-radius: 40px;
    /* border: 1px solid black; */
    padding: 40px;
    width: 75%;
}

.text{
    /* padding: 10px; */
    /* border: 1px solid black; */
    color: black;

}

.text h1{
    text-align: center;
    color: black;
    font-family: 'Open Sans', sans-serif;

}
.heading{
    color: black;
    text-align: center;
    margin-bottom: 30px;
}
.textBox{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* padding: 10px; */
}


@media screen and (max-width:750px){

        .aboutUs{
            display: block;
            margin-bottom: 50px;
            padding: 20px;

        }

        .text{
            margin:0px;
        }

       

        .logo{
            height: 40vh;
            width: 75%;
        }        
        
    
}
.cardImg{
    width: 100%;
    height: 100%;
}
.cardBody{
    padding: 10px;
    word-spacing: 1px;
    line-height: 20px;
    position: relative;
    height: 130px;
}

@media screen and (max-width:800px){
    .cardBody{
        height: 220px;
    }
}

@media screen and (max-width:570px){
    .cardBody{
        height: 250px;
    }
}

@media screen and (max-width:360px){
    .cardBody{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
.card{
    margin: 15px;
    border-radius: 10px;
}

.project{
    background: #4CA1AF;  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white); 
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 100px 0px;
    /* padding: 60px 0px; */
}

.project h1{
    text-align: center;
    margin-bottom: 70px;
}

.projectItems{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    margin: 0px 5px;
}

@media screen and (max-width:800px){
    .projectItems{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width:530px){
    .projectItems{
        grid-template-columns: repeat(2,1fr);
    }
}

.landingImage {
    /* Location of the image */
    background-image: url(/static/media/background-photo.ddfebb53.jpg);
    
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color:#464646;
    height: 100vh;
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 767px) {
    .landingImage {
      background-image: url(/static/media/background-photo-mobile-devices.dbe093f4.jpg);
    }
      }

.containerText{
    display: table;
  padding-top: 80px;
  width: 100%;
} 
 .typewriterContent{
    display: table-cell;
  vertical-align: middle;
  text-align: center;
} 
      .landingText  {
        padding-top: 80px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: #efecec;
        text-transform: uppercase;
        font-size: 60px;
        line-height: 50px;
        font-weight: 400;
        margin-top: 100px;

   
      }

      @media only screen and (max-width: 340px){
        .containerText {
            position: relative;
            display: block;
            float: left;
            margin: 0 auto;
            padding: 80px 0 0 0;
            height: auto;
          }

          .typewriterContent {  
            display: inline;
            margin: 0 auto;
            padding-top: 80px;
            vertical-align: baseline;
          }
          
      }
 .serviceCardBody {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* border: 1px solid black; */
  padding: 13px 30px;

}



@media screen and (max-width:800px){
    .serviceCardBody{
      text-align: center;
    }
}

@media screen and (max-width:328px){
  .serviceCardBody{
    padding: 13px 15px;
  }
}
.cardImage {
    background: grey;
    width: 80%;
    
  } 

.cardImage img{
    height:100%;
    width: 100%;
}

@media screen and (max-width:474px){
    .cardImage{
        display: none;
    }
  }
.serviceCard{
    display:flex;
    margin: 15px;
    border-radius: 10px;
    background-color: white;
    height: 220px;
    position: relative;
    /* height: fit-content; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* border: 1px solid black; */

}

@media screen and (max-width:1146px){
    .serviceCard{
      height: 240px;
    }
  }

@media screen and (max-width:1012px){
    .serviceCard{
      height: 260px;
      
    }
  }

  @media screen and (max-width:874px){
    .serviceCard{
      height: 260px;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width:474px){
    .serviceCard{
      height: 180px;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width:400px){
    .serviceCard{
      height: 250px;
      font-size: 0.85rem;
    }
  }
.service{
    /* background:#f3f3f3; */
    background: #4CA1AF;  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white); 
    width: 100%;
    margin: 100px 0px;
    padding: 35px 50px 50px 30px;
    /* height: fit-content; */
    
}

.service h1{
    text-align: center;
    margin-bottom: 60px;
}

.serviceItem{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
    /* border: 1px solid black; */
    /* grid-column-gap: 10px; */
    /* margin: 0px 5px; */
}

@media screen and (max-width:800px){
    .serviceItem{
        grid-template-columns: repeat(1,1fr);
    }
}
.projectImgCarousel{
    padding: 10px;
    /* border: 1px dotted black; */
    position: -webkit-sticky;
    position: sticky;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgSlide{
    opacity: 0;
    transition-duration: 1s ease;
}

.imgSlide.active{
    opacity: 1;
    transition-duration: 1s;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}

.projectImage{
    width: 70vh;
    height: 62vh;
    border-radius: 10px;
}

#left-arrow{
    opacity: 0;
}
#right-arrow{
    opacity: 0;
}

@media screen and (max-width:1250px) {
    .projectImage{
        width: 60vh;
    }
}

@media screen and (max-width:980px) {
    .projectImage{
        width: 55vh;
    }
}

@media screen and (max-width:850px) {
    .projectImage{
        width: 70vh;
    }
}

@media screen and (max-width:530px) {
    .projectImage{
        width: 55vh;
    }
}

@media screen and (max-width:415px) {
    .projectImage{
        width: 47vh;
    }
}

@media screen and (max-width:360px) {
    .projectImage{
        width: 42vh;
    }
}

.projectBody{
    padding: 10px;
    /* border: 1px dotted black; */
}
.projectDetail{
    
    padding: 10px;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 45% 55%;
    grid-column-gap: 5px;
}

@media screen and (max-width:770px) {
    .projectDetail{
     display: inline;
    }
 }
.contact-form{
    background: #000000;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: block;
    padding: 25px;
    color: white;
    width: 750px;
    height: 485px;
    border-radius: 10px;
    /* border: 1px solid black; */
}

.form-item > input{
    width: 300px;
    border-radius: 5px;
}

.form-elements{
    padding: 0px 20px;
    
}

.form-header{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.form-items{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    
}

label{
    margin-bottom: 7px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
}

.form-item{
    display: grid;
    padding: 15px;
}

.submit-btn{
    margin: 30px 0px 15px 15px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
}

.green-tick{
    margin-top: 60px;
    text-align: center;
}

.message-sent-text{
    margin: 40px;
    font-size: large;
}

.contact-form-loader{
    margin-top: 32px;
    margin-left: 25px;
}

@media screen and (max-width:810px) {
    .form-item > input{
        width: 100%;
    }
 }

@media screen and (max-width:570px) {
    .contact-form{
        width: 330px;
        height: 660px;
    }
    .form-items{
        display: block;
    }
    
    .form-item > textarea {
        width: 100%; 
    }

    .green-tick{
        margin-top: 120px;
        
    }
}
.contact{
    display: flex;
    justify-content: center;
    margin: 50px;
}

h1,h2,h3,h4,h5,h6{}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}
.footer {
  /* margin-top: 8%; */
  background: linear-gradient(105deg, #6e99e6, #093c94);
  padding-top: 60px;
  padding-bottom: 20px;
}
/*END FOOTER SOCIAL DESIGN*/
.single_footer {
    
}
@media only screen and (max-width: 768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}
.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}
.single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 10px;
}

.footer-info{
   margin-bottom: 35px;
}

.single_footer p {
  color: #fff;
  font-size: 18px;
}
.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single_footer ul li {
}
.single_footer ul li a {
  color: #fff;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: none;
}
.single_footer ul li a:hover {
  color: black;
}

.single_footer_address {
}
.single_footer_address ul {
}
.single_footer_address ul li {
  color: #fff;
}
.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}
.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}
.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}
.subscribe__btn i {
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #ff3666;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  /* margin-top: 40px; */
}

.social_profile h4 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
  }
  .social_profile h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
  }


.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.social_profile ul li {
  float: left;
}
.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.social_profile ul li a:hover {
  background: black;
  border: 1px solid #ff3666;
  color: #fff;
  border: 0px;
}
/*END SOCIAL PROFILE CSS*/
.copyright {
  margin-top: 40px;
  padding-top: 40px;
  color: #fff;
  font-size: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}
.copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}
.copyright a:hover {
  color: black;
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap'); */
.app{
    background: white;  
}
