.slider{
    position: sticky;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.image{
    /* width: 700px;
    height: 600px; */
    width: 700px;
    /* max-height: 100%;
    display: block; */
}

.right-arrow{
    position: absolute;
    top: 50%;
    right: 5%;
    font-size: 3rem;
    color: black;
    z-index: 1;
    cursor: pointer;
    user-select: none;
}

.left-arrow{
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 3rem;
    color: black;
    z-index: 1;
    cursor: pointer;
    user-select: none;
}

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}


@media screen and (max-width:1050px),
screen and (max-height:700px)
{

    .image{
        width: 450px;
        height: 400px;
        border-radius: 10px;
    }
    
}

@media screen and (max-width:500px){

    .image{
        width: 300px;
        height: 250px;
        border-radius: 10px;
    }    

    .left-arrow{
       
        top:48%;
    }

    .right-arrow{
        top:48%;
    }
}


@media screen and (max-width:460px){
    .left-arrow{
        left: 3%;
        top:48%;
    }

    .right-arrow{
        right: 3%;
        top:48%;
    }
}

@media screen and (max-width:370px){

    .slider{
        height: 80vh;
    }

    .image{
        width: 250px;
        height: 200px;
        border-radius: 10px;
    }

    .left-arrow{
        left: 10%;
        top:48%;
    }

    .right-arrow{
        right: 10%;
        top:48%;
    }
    
}