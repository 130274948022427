.project{
    background: #4CA1AF; 
    background: -webkit-linear-gradient(to top, #C4E0E5, #4CA1AF);  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white); 
    width: 100%;
    height: fit-content;
    margin: 100px 0px;
    /* padding: 60px 0px; */
}

.project h1{
    text-align: center;
    margin-bottom: 70px;
}

.projectItems{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    margin: 0px 5px;
}

@media screen and (max-width:800px){
    .projectItems{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width:530px){
    .projectItems{
        grid-template-columns: repeat(2,1fr);
    }
}