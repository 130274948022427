.projectImgCarousel{
    padding: 10px;
    /* border: 1px dotted black; */
    position: sticky;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgSlide{
    opacity: 0;
    transition-duration: 1s ease;
}

.imgSlide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.projectImage{
    width: 70vh;
    height: 62vh;
    border-radius: 10px;
}

#left-arrow{
    opacity: 0;
}
#right-arrow{
    opacity: 0;
}

@media screen and (max-width:1250px) {
    .projectImage{
        width: 60vh;
    }
}

@media screen and (max-width:980px) {
    .projectImage{
        width: 55vh;
    }
}

@media screen and (max-width:850px) {
    .projectImage{
        width: 70vh;
    }
}

@media screen and (max-width:530px) {
    .projectImage{
        width: 55vh;
    }
}

@media screen and (max-width:415px) {
    .projectImage{
        width: 47vh;
    }
}

@media screen and (max-width:360px) {
    .projectImage{
        width: 42vh;
    }
}
