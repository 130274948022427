.projectDetail{
    
    padding: 10px;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 45% 55%;
    grid-column-gap: 5px;
}

@media screen and (max-width:770px) {
    .projectDetail{
     display: inline;
    }
 }