.NavbarItems{
    background: linear-gradient(105deg, #6e99e6, #093c94);  
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: white;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: 500;
}

.nav-links:hover{
   background-color: black;
   color: white;
   border-radius: 4px;
   transition: all 0.2s ease-out;
}

.fa-bars{
    color: white;
}

.menu-icon{
    display: none;
}

@media screen and (max-width:960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: linear-gradient(105deg, #6e99e6, #093c94); 
        left:0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        margin-top: 18px;
    }

    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: darkblue; 
        border-radius: 0;
    }

    .navbar-logo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%,50%);
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times{
        color: white;
        font-size: 2rem;
    }
}