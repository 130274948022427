.serviceCard{
    display:flex;
    margin: 15px;
    border-radius: 10px;
    background-color: white;
    height: 220px;
    position: relative;
    /* height: fit-content; */
    width: fit-content;
    /* border: 1px solid black; */

}

@media screen and (max-width:1146px){
    .serviceCard{
      height: 240px;
    }
  }

@media screen and (max-width:1012px){
    .serviceCard{
      height: 260px;
      
    }
  }

  @media screen and (max-width:874px){
    .serviceCard{
      height: 260px;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width:474px){
    .serviceCard{
      height: 180px;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width:400px){
    .serviceCard{
      height: 250px;
      font-size: 0.85rem;
    }
  }