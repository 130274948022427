:root{
    --primary: #3acbf7;
}

.btn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border:none;
    cursor: pointer;
}

.btn:hover{
    padding: 8px,20px;
    transition: all 0.3s ease-out;
    background: white;
    color: #6568f4;
    transition: 250ms;
}

.btn--primary{
    background-color: var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: white;
    padding: 8px,20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 8px,20px;
    border-radius: 4px;
    font-size: 18px;
    color: white;
}

.btn--medium{
    padding: 12px,26px;
    border-radius: 4px;
    font-size: 20px;
    color: white;
}