 .serviceCardBody {
  width: 100%;
  height: fit-content;
  /* border: 1px solid black; */
  padding: 13px 30px;

}



@media screen and (max-width:800px){
    .serviceCardBody{
      text-align: center;
    }
}

@media screen and (max-width:328px){
  .serviceCardBody{
    padding: 13px 15px;
  }
}