.contact-form{
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: block;
    padding: 25px;
    color: white;
    width: 750px;
    height: 485px;
    border-radius: 10px;
    /* border: 1px solid black; */
}

.form-item > input{
    width: 300px;
    border-radius: 5px;
}

.form-elements{
    padding: 0px 20px;
    
}

.form-header{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.form-items{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    
}

label{
    margin-bottom: 7px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
}

.form-item{
    display: grid;
    padding: 15px;
}

.submit-btn{
    margin: 30px 0px 15px 15px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
}

.green-tick{
    margin-top: 60px;
    text-align: center;
}

.message-sent-text{
    margin: 40px;
    font-size: large;
}

.contact-form-loader{
    margin-top: 32px;
    margin-left: 25px;
}

@media screen and (max-width:810px) {
    .form-item > input{
        width: 100%;
    }
 }

@media screen and (max-width:570px) {
    .contact-form{
        width: 330px;
        height: 660px;
    }
    .form-items{
        display: block;
    }
    
    .form-item > textarea {
        width: 100%; 
    }

    .green-tick{
        margin-top: 120px;
        
    }
}