.cardBody{
    padding: 10px;
    word-spacing: 1px;
    line-height: 20px;
    position: relative;
    height: 130px;
}

@media screen and (max-width:800px){
    .cardBody{
        height: 220px;
    }
}

@media screen and (max-width:570px){
    .cardBody{
        height: 250px;
    }
}

@media screen and (max-width:360px){
    .cardBody{
        height: fit-content;
    }
}