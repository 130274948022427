.landingImage {
    /* Location of the image */
    background-image: url(../../Assets/images/background-photo.jpg);
    
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color:#464646;
    height: 100vh;
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 767px) {
    .landingImage {
      background-image: url(../../Assets/images/background-photo-mobile-devices.jpg);
    }
      }

.containerText{
    display: table;
  padding-top: 80px;
  width: 100%;
} 
 .typewriterContent{
    display: table-cell;
  vertical-align: middle;
  text-align: center;
} 
      .landingText  {
        padding-top: 80px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: #efecec;
        text-transform: uppercase;
        font-size: 60px;
        line-height: 50px;
        font-weight: 400;
        margin-top: 100px;

   
      }

      @media only screen and (max-width: 340px){
        .containerText {
            position: relative;
            display: block;
            float: left;
            margin: 0 auto;
            padding: 80px 0 0 0;
            height: auto;
          }

          .typewriterContent {  
            display: inline;
            margin: 0 auto;
            padding-top: 80px;
            vertical-align: baseline;
          }
          
      }