.aboutUs{
    background:#f3f3f3;
    width: 100%;
    height: fit-content;
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    padding: 40px 100px;
    color: black;
    /* justify-content: center; */
    /* border: 1px solid black; */
    /* background: #4CA1AF; 
    background: -webkit-linear-gradient(to top, #C4E0E5, #4CA1AF);  
    background: linear-gradient(to top,white, #C4E0E5, #4CA1AF,#C4E0E5,white);  */
}

.logo{
    height: 50vh;
    border-radius: 40px;
    /* border: 1px solid black; */
    padding: 40px;
    width: 75%;
}

.text{
    /* padding: 10px; */
    /* border: 1px solid black; */
    color: black;

}

.text h1{
    text-align: center;
    color: black;
    font-family: 'Open Sans', sans-serif;

}
.heading{
    color: black;
    text-align: center;
    margin-bottom: 30px;
}
.textBox{
    height: fit-content;
    /* padding: 10px; */
}


@media screen and (max-width:750px){

        .aboutUs{
            display: block;
            margin-bottom: 50px;
            padding: 20px;

        }

        .text{
            margin:0px;
        }

       

        .logo{
            height: 40vh;
            width: 75%;
        }        
        
    
}