.cardImage {
    background: grey;
    width: 80%;
    
  } 

.cardImage img{
    height:100%;
    width: 100%;
}

@media screen and (max-width:474px){
    .cardImage{
        display: none;
    }
  }